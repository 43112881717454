import React from "react"
import styled from "styled-components"

import { Color, Type } from "../../utilities"

const BannerContainer = styled.div`
  background-color: ${Color.dark_grey};
  background-size: cover;
  padding: 3.5rem 0;
`

const BannerText = styled.div`
  font-family: ${Type.header};
  color: ${Color.white};
  text-align: center;
  line-height: 50%;

  h3 {
    font-weight: bold;
    margin-bottom: 0;
  }

  h4 {
    font-style: italic;
    font-size: 1rem;
    margin-top: 2rem;
    margin-bottom: 0;
  }
`

export const Banner = ({ title, tag }) => (
  <BannerContainer>
    <BannerText>
      <h3>{title}</h3>
      {tag && <h4>{tag}</h4>}
    </BannerText>
  </BannerContainer>
)
