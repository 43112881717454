import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { Color, Type, Media, Col, AlignCenter, Wrapper } from "../../utilities"
import { getFileNode } from "../../../pages/index"

const ItemImage = styled.div`
  display: inline-block;
  padding: 1rem;
  background: ${Color.white};
  box-shadow: 0px 0px 20px -5px ${Color.grey};
  border-radius: 4px;
  margin-top: -5%;
  margin-bottom: 10%;

  ${Media.below.tablet`
    margin-top: 1rem;
  `}
`

const SectionContainer = styled.div`
  background-color: ${Color.white};
`

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4rem;

  ${Col}:nth-child(even) {
    ${ItemImage} {
      background: ${Color.blue};
    }
  }

  ${Media.below.tablet`
  grid-template-columns: 1fr;
`}
`

const ItemTitle = styled.div`
  font-family: ${Type.header};
  color: ${Color.section};
  text-align: center;
  font-weight: bold;
  margin-bottom: 1rem;

  ${Media.below.tablet`
    margin-bottom: .75rem;
  `}
`

const ItemContent = styled.div`
  font-family: ${Type.text};
  color: ${Color.section};
  text-align: center;
  margin-bottom: 2rem;

  ${Media.below.tablet`
    margin-bottom: 1rem;
  `}
`

const sectionInfo = ({ edges }) => [
  {
    image: getFileNode({ edges, name: "balance-blue.png" }).node.childImageSharp
      .fluid.src,
    title: "Highest Quality",
    content:
      "Ut rutrum ipsum ac finibus iaculis. Quisque tempus sit amet orci fringilla laoreet. Proin dignissim tempor auctor.",
  },
  {
    image: getFileNode({ edges, name: "hammer-white.png" }).node.childImageSharp
      .fluid.src,
    title: "Professional Advices",
    content:
      "Ut rutrum ipsum ac finibus iaculis. Quisque tempus sit amet orci fringilla laoreet. Proin dignissim tempor auctor.",
  },
  {
    image: getFileNode({ edges, name: "case-blue.png" }).node.childImageSharp
      .fluid.src,
    title: "Get Appointment",
    content:
      "Ut rutrum ipsum ac finibus iaculis. Quisque tempus sit amet orci fringilla laoreet. Proin dignissim tempor auctor.",
  },
]

export const ServiceSection = () => {
  const { allFile } = useStaticQuery(graphql`
    query ServiceQuery {
      allFile(
        filter: {
          relativePath: {
            in: ["balance-blue.png", "hammer-white.png", "case-blue.png"]
          }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 65) {
                src
              }
            }
          }
        }
      }
    }
  `)

  return (
    <SectionContainer>
      <Wrapper>
        <ServiceGrid>
          {sectionInfo({ edges: allFile.edges }).map((item, ix) => (
            <Col key={ix}>
              <AlignCenter>
                <ItemImage>
                  <img src={item.image} />
                </ItemImage>
              </AlignCenter>
              <ItemTitle>{item.title}</ItemTitle>
              <ItemContent>{item.content}</ItemContent>
            </Col>
          ))}
        </ServiceGrid>
      </Wrapper>
    </SectionContainer>
  )
}
