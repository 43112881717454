import styled from "styled-components"

export const AlignCenter = styled.div`
  text-align: center;
`

export const AlignLeft = styled.div`
  text-align: left;
`

export const AlignRight = styled.div`
  text-align: right;
`
