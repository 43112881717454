import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { Color, Type, Col, Media, ButtonA, Wrapper } from "../../utilities"

const SectionContainer = styled.div`
  background-color: ${Color.blue};
  padding: 4rem 0;

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}
`

const Side1 = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  align-items: center;

  ${Media.below.tablet`
    grid-template-columns: 1fr;
    text-align: center;
  `}
`

const SpecialGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr auto;

  ${Media.below.tablet`
    grid-template-columns: 1fr;
    text-align: center;
  `}
`

const Balance = styled.div`
  margin-right: 2rem;

  ${Media.below.tablet`
    margin-right: 0;
    padding-bottom: 1rem;
  `}
`

const BannerText = styled.div`
  font-family: ${Type.header};
  color: ${Color.white};

  p {
    font-family: ${Type.text};
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 1rem;
  }

  ${Media.below.tablet`
    text-align: center;
    line-height: 220%;
    margin-bottom: 1rem;
  `}
`

export const AppointmentSection = () => {
  const { file } = useStaticQuery(graphql`
    query CallActionQuery {
      file(relativePath: { eq: "balance-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 70) {
            src
          }
        }
      }
    }
  `)
  return (
    <SectionContainer>
      <Wrapper>
        <SpecialGrid>
          <Col>
            <Side1>
              <Balance>
                <img src={file.childImageSharp.fluid.src} />
              </Balance>
              <BannerText>
                <h3>High Quality Law Firm With Personal Attention</h3>
              </BannerText>
            </Side1>
          </Col>
          <Col>
            <ButtonA to="/contact" modifiers={["primary"]}>
              Contact Us
            </ButtonA>
          </Col>
        </SpecialGrid>
      </Wrapper>
    </SectionContainer>
  )
}
