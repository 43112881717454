import React, { Fragment } from "react"
import styled from "styled-components"
import { darken } from "polished"
import { Link } from "gatsby"

import { Color, Wrapper, Flex, Media, Type } from "../../utilities"

const StyledFooter = styled.div`
  background-color: ${Color.dark_blue};
  font-family: ${Type.text};
  font-size: ${Type.basesize};
  padding: 4rem 0rem;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
`

const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `};
`

const ContactCol = styled.div`
  font-family: ${Type.text};
  color: ${Color.white};
  text-align: left;

  h3 {
    font-family: ${Type.text};
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
  }

  p {
    margin-bottom: 0.25rem;
    font-size: 0.9rem;
  }

  a {
    color: ${Color.white};
  }

  ${Media.below.tablet`
    display: block !important;
    text-align: center;
    margin-bottom: 30px;
`};
`

const ContactColBar = styled.div`
  background-color: ${Color.white};
  height: 2px;
  width: 40px;
  margin-bottom: 0.75rem;

  ${Media.below.tablet`
    display: none;
  `};
`

const Copyright = styled.div`
  color: ${Color.grey};
  font-size: 0.9rem;
  margin-top: 0.5rem;

  a {
    text-decoration: none;
    color: ${Color.grey};

    &:hover {
      text-decoration: underline;
    }
  }

  ${Media.below.tablet`
    text-align: center;
  `};
`

const NavCol = styled.div`
  display: block !important;
`
const FooterNavItem = styled(Link)`
  text-decoration: none;
  font-family: ${Type.text};
  color: ${Color.white};
  padding-right: 20px;
  display: inline-grid;

  &:hover {
    color: ${Color.orange};
  }

  ${Media.below.tablet`
    display: block !important;
    text-align: center;
    line-height: 200%;
    padding-right: 0;
  `};
`

export const convertUrl = url =>
  url.replace(`http://${process.env.GATSBY_API_URL}`, "")

const Footer = ({ nav = [], data = {} }) => {
  return (
    <StyledFooter>
      <Wrapper>
        <FooterContainer>
          <ContactCol>
            <h3>Contact Pfost Law, Ltd.</h3>
            <ContactColBar />
            <p>{data.address}</p>
            <p>{data.address_two}</p>
            <br />
            <p>Phone: {data.phone}</p>
            <p>Fax: {data.fax}</p>
            <p>Email: {data.email}</p>
          </ContactCol>
          <NavCol>
            {nav.map(item => (
              <FooterNavItem to={item.url}>{item.name}</FooterNavItem>
            ))}
            <Copyright>
              Pfost Law, Ltd. &copy;2020 - All rights reserved.
              <br />
              Website design by{" "}
              <a href="https://toddprod.com" target="_blank">
                Todd Productions Inc.
              </a>
            </Copyright>
          </NavCol>
        </FooterContainer>
      </Wrapper>
    </StyledFooter>
  )
}

export default Footer
