import React, { Fragment } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import Header from "../Header"
import Footer from "../Footer"
import { GlobalStyles, AppointmentSection } from "../../elements"
import { Color, SEO } from "../../utilities"

const Body = styled.div`
  a {
    color: ${Color.dark_blue};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const fixContentLinks = content => {
  const regex = /^((http|https|ftp):\/\/)/
  if (regex.test(content)) {
    content = content.replace(
      process.env.GATSBY_API_URL,
      process.env.GATSBY_WEB_URL
    )
  }

  return content
}

const nav = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "About",
    url: "/about",
  },
  {
    name: "Practice Areas",
    url: "/practice-areas",
  },
  {
    name: "Attorneys",
    url: "/attorneys",
  },
  {
    name: "Contact",
    url: "/contact",
  },
]

export const Layout = ({ children, title, description }) => {
  const { site } = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
          phone
          fax
          address
          address_two
          email
        }
      }
    }
  `)
  return (
    <Fragment>
      <GlobalStyles />
      <SEO title={title} description={description} />
      <Header nav={nav} data={site.siteMetadata} />
      <Body>
        {typeof children == "function"
          ? children({
              data: site.siteMetadata,
            })
          : children}
      </Body>
      <AppointmentSection />
      <Footer nav={nav} data={site.siteMetadata} />
    </Fragment>
  )
}
