import React, { Fragment } from "react"

export const SaraPfostContact = () => (
  <Fragment>
    <ul>
      <li>
        Phone: <a href="tel:4198724513">419-872-4513</a>
      </li>
      <li>Fax: 419-872-4514</li>
      <li>
        Email: <a href="email:spfost@pfostlaw.com">spfost@pfostlaw.com</a>
      </li>
    </ul>
  </Fragment>
)

export const BrianSmithContact = () => (
  <Fragment>
    <ul>
      <li>
        Direct Phone: <a href="tel:4199301365">419-930-1365</a>
      </li>
      <li>
        General Phone: <a href="tel:4198724513">419-872-4513</a>
      </li>
      <li>Fax: 419-872-4514</li>
      <li>
        Email: <a href="email:bsmith@pfostlaw.com">bsmith@pfostlaw.com</a>
      </li>
    </ul>
  </Fragment>
)
