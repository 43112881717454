import React, { useState } from "react"
import { lighten } from "polished"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import lawpay from "../../../images/law-pay.png"

import {
  Color,
  Wrapper,
  Type,
  Media,
  Grid,
  Col,
  AlignCenter,
} from "../../utilities"

const HeaderContainer = styled.div`
  background-size: cover;
  background-color: ${Color.white};
  background-position: center;
  padding: 0;
`

const HeaderGrid = styled(Grid)`
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 1rem;

  ${Media.below.desktop`
    grid-template-columns: 1fr 1fr;
  `}

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}
`

const Logo = styled(Link)`
  display: inline-block;
  margin: 1rem 2rem;

  ${Media.below.tablet`
    top: 0;
    height: auto;
    text-align: center;
    .logo {
    max-width: 200px;
  }
  `}
`

const InnerGrid = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  font-family: ${Type.header};
  max-width: 500px;
  margin-left: auto;
  padding: 0.9rem 0rem;
  line-height: 30%;

  .phone1 {
    ${Media.below.tablet`
      display: none;
    `}
  }
  .phone2 {
    font-size: 1.25rem;
    font-weight: bold;
    color: ${Color.blue};
    text-decoration: none;

    ${Media.below.tablet`
      display: none;
    `}
  }

  ${Media.below.tablet`
    grid-template-columns: 1fr;
    text-align: center;
    margin-left: 0;
    padding: 0;
    margin: 0;
  `}
`

const NavItem = styled(Link)`
  position: relative;
  color: ${Color.grey};
  font-size: 1rem;
  font-family: ${Type.header};
  display: flex;
  padding: 1.5rem 9px;
  text-decoration: none;
  height: 100%;
  box-sizing: border-box;
  align-content: center;
  align-items: center;

  &:hover {
    color: ${Color.blue} !important;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -6px;
      bottom: 0;
      margin-top: 7px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid ${Color.blue};
      height: 0;
      width: 0;

      ${Media.below.tablet`
        display: none;
      `}
    }
  }

  ${Media.below.tablet`
    display: flex;
    font-family: ${Type.text};
    color: ${lighten(0.3, Color.blue)};
    font-style: bold;
    font-size: 2rem;
  `};
`

const ExitButton = styled.span`
  position: absolute;
  z-index: 9001;
  right: 0px;
  top: 0px;
  font-size: 2rem;
  height: 40px;
  width: 40px;
  display: none;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-bottom: 5px;
  box-sizing: border-box;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  background: ${Color.white};
  border-radius: 100%;

  ${Media.below.tablet`
    display: flex;
  `}
`

const Burg = styled.div`
  display: none;
  z-index: 101;
  position: absolute;
  left: 0px;
  width: 32px;
  height: 5px;
  top: 8%;
  left: 8%;
  cursor: pointer;
  background: ${Color.blue};

  &::before,
  &::after {
    left: 0;
    content: "";
    margin-top: -12px;
    position: absolute;
    width: 100%;
    height: 5px;
    background: ${Color.blue};
  }

  &::after {
    margin-top: 12px;
  }

  ${Media.below.tablet`
    display: block;
  `}
`

const LawPay = styled(Link)`
  display: inline-block;
  img {
    height: 80px;
  }

  ${Media.below.tablet`
    text-align: center;
    padding-bottom: 1.5rem;
  `}
`

const NavUL = styled.ul`
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  display: flex;

  ${Media.below.tablet`
    display: ${props => (props.on ? `flex` : `none`)};
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 9000;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.85);
  `}
`

const Header = ({ nav = [], data = {} }) => {
  const [on, setOn] = useState(false)
  const { file } = useStaticQuery(graphql`
    query HeaderQuery {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            src
          }
        }
      }
    }
  `)
  return (
    <HeaderContainer>
      <Wrapper>
        <HeaderGrid>
          <Burg onClick={() => setOn(!on)} />
          <Col>
            <NavUL on={on}>
              <ExitButton onClick={() => setOn(!on)}>x</ExitButton>
              {nav.map(item => (
                <NavItem to={item.url}>{item.name}</NavItem>
              ))}
            </NavUL>
          </Col>
          <AlignCenter>
            <Logo to="/">
              <img src={file.childImageSharp.fluid.src} alt={data.title} />
            </Logo>
          </AlignCenter>
          <InnerGrid>
            <Col>
              <p class="phone1">Phone Number:</p>
              <a class="phone2" href={`tel:${data.phone}`}>
                {data.phone}
              </a>
            </Col>
            <Col>
              <LawPay to={data.law_pay_link}>
                <img src={lawpay} />
              </LawPay>
            </Col>
          </InnerGrid>
        </HeaderGrid>
      </Wrapper>
    </HeaderContainer>
  )
}

export default Header
