import React from "react"

export class Toggle extends React.Component {
  state = {
    on: false,
  }

  componentDidMount() {
    if (this.props.on === true) {
      this.setState({
        ...this.state,
        on: true,
      })
    }
  }

  toggle = () => {
    this.setState({
      on: !this.state.on,
    })
  }

  render() {
    const { children } = this.props
    return children({
      on: this.state.on,
      toggle: this.toggle,
    })
  }
}
