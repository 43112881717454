export const Color = {
  blue: "#284F78",
  grey: "#828282",
  dark_grey: "#414141",
  gold: "#CFA161",
  dark_blue: "#151821",
  text: "#444444",
  section: "#141c32",
  orange: "#FE533D",
  yellow: "#fceb26",
  red: "#FF0000",
  white: "#fff",
  black: "#000",
}
