import React, { Fragment } from "react";

import { Loading } from "../Loading";
import { ErrorAlert } from "../ErrorAlert";

export const ErrorLoadBlock = ({ loading, error }) => (
  <Fragment>
    {loading && <Loading />}
    {error && <ErrorAlert error={error} />}
  </Fragment>
);
