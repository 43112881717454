import React, { useState } from "react";

const ApiReactContext = React.createContext({});

export const ApiContext = ({ children }) => {
  const [state, setState] = useState({
    queryQueue: [],
    apiStock: {}
  });

  return (
    <ApiReactContext.Provider
      value={{
        ...state,
        addToQueue: queue => {
          setState({
            ...state,
            queryQueue: [...state.queryQueue, ...queue]
          });
        },
        removeFromQueue: id => {
          setState({
            ...state,
            queryQueue: state.queryQueue.filter(queue => queue !== id)
          });
        },

        addToStock: ({ id, data }) => {
          let apiStock = state.apiStock;
          const old = apiStock[id];
          apiStock[id] = { ...old, ...data };
          setState({
            ...state,
            apiStock
          });
        },

        removeFromStock: id => {
          const { [id]: _, ...apiStock } = state.apiStock;
          setState({
            ...state,
            apiStock
          });
        }
      }}
    >
      {children}
    </ApiReactContext.Provider>
  );
};

export const ApiContextConsumer = ApiReactContext.Consumer;
