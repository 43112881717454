import React from "react"
import styled from "styled-components"

import {
  Color,
  Type,
  Media,
  ButtonA,
  AlignCenter,
  Grid,
  Col,
  Wrapper,
} from "../components/utilities"

import { Layout } from "../components/layouts/Layout"
import { ServiceSection, Section, Icon } from "../components/elements"

/**
 * Returns node from graphql based on name
 */
export const getFileNode = ({ edges, name }) =>
  edges.find(item => item.node.relativePath == name)

const BackgroundImg = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
  height: 100%;
  padding: 4rem;
  padding-bottom: 6rem;
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${props =>
    props.background &&
    `
    background-image: url('${props.background}');
  `}

  ${Media.below.tablet`
    background-size: auto;
    grid-template-columns: 1fr;
    padding: 2rem;
  `}
`

const BannerContent = styled.div`
  text-align: center;

  h2 {
    font-family: ${Type.header};
    color: ${Color.white};
    line-height: 120%;
    text-shadow: 2px 2px 4px ${Color.black};
    font-weight: bold;
    margin-bottom: 1rem;

    ${Media.below.tablet`
      line-height: 120%;
    `}
  }

  p {
    font-family: ${Type.text};
    color: ${Color.white};
    font-size: 1.25rem;
    text-shadow: 2px 2px 4px ${Color.black};
    margin-bottom: 1rem;
  }
`

const Quote = styled.div`
  display: flex;
  font-family: ${Type.header};
  padding: 2rem;
  font-size: 1.5rem;
  font-style: italic;
  line-height: 150%;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
`

const IndexPage = ({ data }) => (
  <Layout title="Small Scale Law Firm">
    <BackgroundImg background={data.file.childImageSharp.fluid.src}>
      <BannerContent>
        <h2>
          We are a small law firm
          <br />
          providing high quality,
          <br />
          client-centered legal services.
        </h2>

        <br />
        <ButtonA to="/about" modifiers={["primary"]}>
          Learn More
        </ButtonA>
      </BannerContent>
    </BackgroundImg>

    {/* <ServiceSection /> */}
    <Section>
      <AlignCenter>
        <Icon image={data.allFile.edges[0].node.childImageSharp.fluid.src} />
        <h2>About Pfost Law, Ltd.</h2>
        <h4>Learn how our law firm helps our clients</h4>
      </AlignCenter>
      <br />
      {/* <Grid modifiers={["gap"]}>
        <Col>
          <p>
            The attorneys at Pfost Law, Ltd. are honored to represent
            individuals, families, and businesses across Ohio and Michigan. Our
            primary areas are Estate Planning, Probate and Trust Administration,
            Family Law, Real Estate Transactions, and Business Law. We take
            great pride in our client-centered approach to the practice of law
            and would be honored to represent you in your next legal matter.
          </p>
        </Col>
        <Col>
          <Quote>
            The principle of Law is lies in the accurate idea manipulation of
            subtle throughout the series of lawyer cases all around.
          </Quote>
        </Col>
      </Grid> */}
      <Wrapper maxWidth="800px">
        <AlignCenter>
          <p>
            The attorneys at Pfost Law, Ltd. are honored to represent
            individuals, families, and businesses across Ohio and Michigan. Our
            primary practice areas are Estate Planning, Probate and Trust
            Administration, Family Law, Real Estate Transactions, and Business
            Law. We take great pride in our client-centered approach to the
            practice of law and would be honored to represent you in your next
            legal matter.
          </p>
        </AlignCenter>
      </Wrapper>
    </Section>
  </Layout>
)

export default IndexPage

export const indexQuery = graphql`
  query IndexQuery {
    file(relativePath: { eq: "banner2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          src
        }
      }
    }
    allFile(filter: { relativePath: { eq: "balance-gold.png" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 30) {
              src
            }
          }
        }
      }
    }
  }
`
