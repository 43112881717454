import React, { Fragment } from "react"

export const convertNewLineToBr = (string = "") =>
  string.replace(/(?:\r\n|\r|\n)/g, "<br>")

export const ConvertNewLineBR = ({ children, ...props }) => (
  <Fragment>
    <span
      dangerouslySetInnerHTML={{ __html: convertNewLineToBr(children) }}
    ></span>
  </Fragment>
)
